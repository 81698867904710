// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-blogs-list-js": () => import("./../../../src/templates/blogs-list.js" /* webpackChunkName: "component---src-templates-blogs-list-js" */),
  "component---src-templates-case-studies-details-js": () => import("./../../../src/templates/case-studies-details.js" /* webpackChunkName: "component---src-templates-case-studies-details-js" */),
  "component---src-templates-case-studies-list-js": () => import("./../../../src/templates/case-studies-list.js" /* webpackChunkName: "component---src-templates-case-studies-list-js" */),
  "component---src-templates-resources-list-js": () => import("./../../../src/templates/resources-list.js" /* webpackChunkName: "component---src-templates-resources-list-js" */)
}

